.section-header {
  text-align: center;
  margin-bottom: 3rem;
  position: relative;

  &__subtitle {
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #00d31f;
    margin-bottom: 0.5rem;
    font-weight: 600;
    background: linear-gradient(45deg, #00b31a, #00d31f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__title {
    font-size: 2.5rem;
    font-weight: 800;
    color: #1a1a1a;
    margin-bottom: 1rem;
    text-transform: uppercase;
    position: relative;
  }

  &__underline {
    width: 60px;
    height: 4px;
    background: linear-gradient(to right, #00b31a, #00d31f);
    margin: 0 auto;
    transition: width 0.3s ease;
  }

  &:hover {
    .section-header__underline {
      width: 80px;
    }
  }

  @media (max-width: 768px) {
    &__title {
      font-size: 1.5rem;
      align-items: center;
    }
  }
}
