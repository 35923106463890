.testimonials-section {
  max-width: 1200px;
  margin: 0 auto;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.6s ease-out;

  &.visible {
    opacity: 1;
    transform: translateY(0);
    padding-bottom: 50px;
  }

  .profile-card {
    background: rgba(255, 255, 255, 0.95);
    border-radius: 24px;
    padding: 3rem;
    box-shadow: 0 4px 20px rgba(0, 255, 0, 0.08), 0 0 0 1px rgba(0, 255, 0, 0.1);
    backdrop-filter: blur(8px);

    .logo-container {
      text-align: center;
      margin-bottom: 2rem;

      img {
        max-width: 150px;
        height: auto;
      }
    }

    .description {
      color: #555;
      font-size: 1.1rem;
      line-height: 1.8;
      margin-bottom: 3rem;
      padding: 0 1rem;
      text-align: justify;
    }

    .expertise-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1.5rem;
      padding: 1rem;

      .expertise-card {
        background: rgba(255, 255, 255, 0.8);
        border-radius: 16px;
        padding: 1.5rem;
        transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
        position: relative;
        overflow: hidden;
        border: 1px solid rgba(0, 255, 0, 0.1);

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(45deg, transparent, rgba(0, 255, 0, 0.05), transparent);
          transform: translateX(-100%);
          transition: transform 0.6s ease;
        }

        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 8px 24px rgba(0, 255, 0, 0.12);
          border-color: rgba(0, 255, 0, 0.3);

          &::before {
            transform: translateX(100%);
          }
        }

        .icon {
          font-size: 2.5rem;
          margin-bottom: 1rem;
          display: block;
          text-align: center;
        }

        h3 {
          color: #00b31a;
          font-size: 1.2rem;
          margin-bottom: 0.8rem;
          font-weight: 600;
          text-align: center;
        }

        p {
          color: #666;
          line-height: 1.5;
          font-size: 0.95rem;
          text-align: center;
        }
      }
    }
  }

  .contact-section {
    margin-top: 3rem;
    text-align: center;
    background: rgba(255, 255, 255, 0.95);
    border-radius: 24px;
    padding: 3rem;
    box-shadow: 0 4px 20px rgba(0, 255, 0, 0.08), 0 0 0 1px rgba(0, 255, 0, 0.1);

    h2 {
      color: #00b31a;
      font-size: 2rem;
      margin-bottom: 1rem;
      font-weight: 700;
    }

    p {
      color: #555;
      font-size: 1.1rem;
      margin-bottom: 2rem;
    }

    .contact-button {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      color: #00b31a;
      font-size: 1.1rem;
      font-weight: 500;
      padding: 1rem 2.5rem;
      border: 2px solid rgba(0, 255, 0, 0.3);
      border-radius: 99px;
      text-decoration: none;
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      background: linear-gradient(to right, transparent 50%, rgba(0, 255, 0, 0.1) 50%);
      background-size: 200% 100%;
      background-position: left bottom;
      margin-bottom: 2rem;

      &:hover {
        background-position: right bottom;
        transform: translateY(-2px);
        border-color: #00b31a;
        box-shadow: 0 4px 12px rgba(0, 255, 0, 0.15);
      }
    }

    .social-connect {
      margin-top: 2rem;
    }
  }

  @media screen and (max-width: 1200px) {
    padding: 3rem 1.5rem;

    .expertise-grid {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;

    .expertise-grid {
      grid-template-columns: 1fr !important;
    }

    .contact-section {
      padding: 2rem;
    }
  }
}
