.latest-projects {
  margin: 2rem auto;
  max-width: 1200px;
  padding: 0 1rem;

  &__header {
    text-align: center;
    margin-bottom: 3rem;

    h2 {
      color: #111827;
      margin-top: 0.5rem;
    }
  }

  .projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 2rem;
  }

  .specializing-header,
  .qualification-header,
  .testimonials-header {
    margin-bottom: 3rem;
  }

  .project-card {
    background: white;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);

      .project-card__image img {
        transform: scale(1.05);
      }
    }

    &__image {
      position: relative;
      height: 240px;
      overflow: hidden;
      background: #f3f4f6;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.5s ease;
      }
    }

    &__overlay {
      position: absolute;
      inset: 0;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9));
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease;

      &.active {
        opacity: 1;
      }

      &-content {
        padding: 2rem;
        text-align: center;
        color: white;
      }
    }

    .technologies {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      justify-content: center;
      margin-bottom: 1.5rem;
    }

    .tech-tag {
      background: rgba(255, 255, 255, 0.1);
      padding: 0.4rem 0.8rem;
      border-radius: 2rem;
      font-size: 0.85rem;
      backdrop-filter: blur(4px);
    }

    .github-link {
      display: inline-block;
      background: #22c55e;
      color: white;
      padding: 0.75rem 2rem;
      border-radius: 0.5rem;
      font-weight: 500;
      transition: all 0.2s ease;

      &:hover {
        background: #16a34a;
        transform: translateY(-2px);
      }
    }

    &__content {
      padding: 1.5rem;

      .title {
        font-size: 1.25rem;
        font-weight: 600;
        color: #111827;
        margin-bottom: 0.75rem;
      }

      .description {
        color: #4b5563;
        line-height: 1.6;
        font-size: 0.95rem;
      }
    }
  }

  @media (max-width: 768px) {
    .projects-grid {
      grid-template-columns: 1fr;
    }

    .project-card {
      margin: 0 auto;
      max-width: 400px;

      &__image {
        height: 200px;
      }
    }
  }
}
