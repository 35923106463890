.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  backdrop-filter: blur(4px);
}

.modal-content {
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  background: white;
  padding: 2.5rem;
  border-radius: 15px;
  width: 90%;
  max-width: 600px;
  margin: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  position: relative;
  max-height: 80vh;
  overflow-y: auto;

  h2 {
    color: #00d31f;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    padding-right: 2.5rem;
  }

  .modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    color: #666;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
      color: #00d31f;
    }
  }

  .modal-body {
    margin: 1rem 0;

    h3 {
      color: #333;
      font-size: 1.3rem;
      margin-bottom: 1rem;
    }

    h4 {
      color: #444;
      font-size: 1.1rem;
      margin: 1.5rem 0 0.5rem;
    }

    p {
      color: #666;
      line-height: 1.6;
      margin-bottom: 1rem;
    }

    ul {
      list-style: disc;
      margin-left: 1.5rem;
      margin-bottom: 1rem;

      li {
        color: #666;
        line-height: 1.6;
        margin-bottom: 0.5rem;
      }
    }
  }

  .modal-content-section {
    padding: 0 1rem;

    .content-group {
      margin-bottom: 1.5rem;
    }

    .intro {
      font-size: 1.1rem;
      color: #555;
      margin-bottom: 1.5rem;
    }
  }
}

@media (max-width: 768px) {
  .modal-content {
    width: 95%;
    margin: 1rem;
    padding: 1.5rem;
  }
}
