.hero {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px; /* Padding uniquement pour les écrans desktop */

  @media (max-width: 768px) {
    padding: 0; /* Supprime le padding sur les petits écrans */
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;

    @media (max-width: 768px) {
      flex-direction: column;
      text-align: center;
    }
  }

  &__text {
    flex: 1;
    max-width: 600px;

    &-greeting {
      font-size: 1.5rem;
      font-weight: bold;
      text-transform: uppercase;
      color: #009b15;

      span {
        color: #009b15;
        font-weight: bold;
      }
    }

    &-title {
      font-size: 2.5rem;
      font-weight: bold;
      margin: 10px 0;
      color: #1a1a1a;

      @media (max-width: 768px) {
        font-size: 2rem;
        text-align: center;
      }
    }

    &-description {
      font-size: 1.2rem;
      line-height: 1.6;
      margin-bottom: 20px;
      color: #4a4a4a;

      @media (max-width: 768px) {
        font-size: 1rem;
        text-align: justify;
        margin: 0 10px; /* Ajoute une marge pour équilibrer les côtés */
      }
    }
  }

  &__image {
    flex: 0 0 auto;
    width: 300px;
    height: auto;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    transition: transform 0.5s ease;

    @media (max-width: 768px) {
      width: 200px;
    }

    &:hover {
      transform: scale(1.1);
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__skills {
    margin-top: 50px;

    &-title {
      text-align: center;
      font-size: 2rem;
      color: #1a1a1a;
      margin-bottom: 30px;
      transition: transform 0.3s ease, color 0.3s ease;

      &:hover {
        transform: scale(1.1);
        color: #009b15;
      }

      @media (max-width: 768px) {
        font-size: 1.8rem;
      }
    }

    &-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      justify-content: center;
      gap: 20px;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 15px;
      }
    }

    &-card {
      background: #f9f9f9;
      border-radius: 10px;
      padding: 20px;
      text-align: center;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        transform: translateY(-5px) scale(1.05);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

        .hero__skills-icon {
          transform: scale(1.2);
        }
      }

      &-icon {
        font-size: 2rem;
        color: #009b15;
        margin-bottom: 10px;
        transition: transform 0.3s ease;
      }

      h3 {
        font-size: 1.5rem;
        margin-bottom: 10px;
        color: #1a1a1a;
        font-weight: bold;

        @media (max-width: 768px) {
          font-size: 1.2rem;
        }
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          font-size: 1rem;
          color: #4a4a4a;
          margin-bottom: 5px;

          &:hover {
            color: #009b15;
          }
        }
      }
    }
  }
}
