.section-header__subtitle {
  padding-top: 30px;
}

.specializing {
  padding: 4rem 0;
  max-width: 1200px;
  margin: 0 auto;

  .card {
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.98);
    backdrop-filter: blur(10px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  }

  .specializing-header {
    padding: 2rem 0;
    text-align: center;

    h2 {
      margin-top: 0.5rem;
      font-size: 2rem;
      font-weight: 700;
    }

    p {
      color: #00d31f;
      font-weight: 500;
    }
  }

  .filters {
    display: flex;
    gap: 1rem;
    margin: 2rem 0;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 2rem;

    .filter-btn {
      padding: 0.75rem 1.5rem;
      border-radius: 25px;
      font-size: 0.95rem;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s ease;
      border: 2px solid transparent;
      background: #f8f9fa;
      color: #4a5568;

      &:hover {
        transform: translateY(-2px);
        background: rgba(0, 211, 31, 0.1);
        color: #00d31f;
      }

      &.active {
        background: rgba(0, 211, 31, 0.15);
        color: #00d31f;
        border-color: rgba(0, 211, 31, 0.3);
        box-shadow: 0 4px 12px rgba(0, 211, 31, 0.1);
      }
    }
  }

  .skills-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
    padding: 2rem;

    .skill-card {
      background: white;
      padding: 1.75rem;
      border-radius: 16px;
      border-top: 4px solid #00d31f;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);
      transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.4s cubic-bezier(0.4, 0, 0.2, 1);

      &:hover {
        transform: translateY(-8px) scale(1.02);
        box-shadow: 0 20px 40px rgba(0, 211, 31, 0.15);

        .skill-icon {
          transform: scale(1.1) rotate(5deg);
        }
      }

      .skill-icon {
        font-size: 2.5rem;
        margin-bottom: 1.25rem;
        transition: transform 0.3s ease;
      }

      .skill-name {
        font-size: 1.35rem;
        font-weight: 700;
        color: #1a1a1a;
        margin-bottom: 0.75rem;
      }

      .skill-desc {
        color: #4a5568;
        line-height: 1.6;
        margin-bottom: 1.5rem;
        font-size: 0.95rem;
      }

      .progress-container {
        height: 8px;
        background: #f3f4f6;
        border-radius: 999px;
        overflow: hidden;
        margin-bottom: 0.75rem;
      }

      .progress-bar {
        height: 100%;
        width: 0;
        background: linear-gradient(to right, #00b31a, #00d31f);
        border-radius: 999px;
        transition: width 0.2s ease-out;
      }

      .skill-value {
        text-align: right;
        color: #00d31f;
        font-weight: 600;
        font-size: 1.1rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .specializing {
    padding: 2rem 1rem;

    .specializing-header {
      padding: 1.5rem 0;

      h2 {
        font-size: 1.75rem;
      }
    }

    .skills-grid {
      grid-template-columns: 1fr;
      padding: 1.5rem;
    }
  }
}
