.qualification-section {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  position: relative;
  overflow: hidden;

  @media (max-width: 480px) {
    padding: 1rem;
  }

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  .education-container {
    display: flex;
    gap: clamp(2rem, 5vw, 4rem);
    margin: 2rem auto;
    position: relative;
    width: 100%;
    max-width: 95%;

    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      width: 90%;

    }

    @media (max-width: 480px) {
      margin: 1rem auto;
      gap: 1.5rem;
      width: 80%;
    }
  }

  .specializing-header,
  .qualification-header,
  .testimonials-header {
    margin-bottom: clamp(1.5rem, 4vw, 3rem);
    text-align: center;

    @media (max-width: 480px) {
      margin-bottom: 1rem;

      .section-header {
        &__subtitle {
          font-size: 0.8rem;
          margin-bottom: 0.4rem;
        }

        &__title {
          font-size: 1.3rem;
          line-height: 1.2;
        }
      }
    }
  }

  .school-info {
    position: sticky;
    top: 100px;
    width: clamp(180px, 22vw, 250px);
    height: fit-content;
    padding: clamp(1.2rem, 3vw, 2.5rem);
    background: rgba(0, 179, 26, 0.05);
    border-radius: clamp(0.8rem, 2vw, 1.5rem);
    border: 1px solid rgba(0, 179, 26, 0.15);
    backdrop-filter: blur(10px);
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 8px 32px rgba(0, 179, 26, 0.08);
    align-self: flex-start;
    z-index: 2;

    @media (max-width: 1024px) {
      position: relative;
      top: 0;
      width: 85%;
      max-width: 280px;
      margin: 0 auto;
      transform: none !important;
    }

    @media (max-width: 480px) {
      width: 85%;
      padding: 1rem;
      border-radius: 0.75rem;
    }

    .school-logo {
      width: min(80%, 250px);
      border-radius: clamp(0.75rem, 1.5vw, 1rem);
      margin-bottom: clamp(1rem, 3vw, 2rem);

      @media (max-width: 480px) {
        width: 70%;
        margin-bottom: 0.8rem;
      }
    }

    .button-container {
      width: 100%;
      margin-top: clamp(0.8rem, 2vw, 1.5rem);

      .cv-button {
        width: 100%;
        padding: clamp(0.5rem, 1.5vw, 0.8rem) clamp(1rem, 3vw, 2rem);
        font-size: clamp(0.8rem, 1.5vw, 0.95rem);

        @media (max-width: 480px) {
          padding: 0.5rem 1rem;
          font-size: 0.8rem;
        }
      }
    }
  }

  .qualifications-timeline {
    flex: 1;
    min-width: 0;
    padding-left: clamp(1rem, 4vw, 3rem);
    padding-right: clamp(0.5rem, 2vw, 1rem);

    @media (max-width: 1024px) {
      width: 90%;
      max-width: 600px;
      padding: 0 1rem;
    }

    @media (max-width: 480px) {
      width: 100%;
      padding: 0 0.5rem;
    }

    &::before {
      @media (max-width: 480px) {
        display: none;
      }
    }

    .qualification-item {
      margin-bottom: clamp(2rem, 5vw, 4rem);
      padding: clamp(1rem, 3vw, 2rem);
      width: 95%;

      @media (max-width: 768px) {
        width: 90%;
        margin-bottom: 1.5rem;
        padding: 1rem;
      }

      @media (max-width: 480px) {
        margin-bottom: 1.25rem;
        padding: 0.8rem;
        border-radius: 0.6rem;
        width: 85%;
      }

      .year-badge {
        @media (max-width: 480px) {
          padding: 0.2rem 0.6rem;
          font-size: 0.65rem;
          right: 0.8rem;
          top: -0.6rem;
        }
      }

      .tile {
        width: 100%;
        height: 100%;
        padding: 0.8rem;
        margin: 0;
        box-sizing: border-box;
        @media (max-width: 480px) {
          width: 50%;
          &__title {
            font-size: 0.85rem;
            margin-bottom: 0.4rem;
          }

          &__medium-title {
            font-size: 0.8rem;
            margin-bottom: 0.4rem;
          }

          &__desc {
            font-size: 0.75rem;
            line-height: 1.3;
          }
        }
      }
    }
  }
}

.qualification-item {
  margin-bottom: clamp(2rem, 5vw, 4rem);
  padding: clamp(1rem, 3vw, 2rem);
  background: rgba(0, 179, 26, 0.03);
  border: 1px solid rgba(0, 179, 26, 0.1);
  border-radius: clamp(0.8rem, 1.5vw, 1.2rem);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  transform: translateX(-20px);
  animation: slideIn 0.5s ease forwards;
  width: calc(100% - 1rem);

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 1.5rem;
    padding: 1rem;
  }

  @media (max-width: 480px) {
    margin-bottom: 1.25rem;
    padding: 0.8rem;
    border-radius: 0.6rem;
    width: 95%;
  }

  &:hover {
    @media (hover: hover) {
      transform: translateX(5px);
      background: rgba(0, 179, 26, 0.05);
      border-color: rgba(0, 179, 26, 0.2);
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: clamp(-2rem, -4vw, -3.65rem);
    top: 2.5rem;
    width: clamp(0.8rem, 1.2vw, 1rem);
    height: clamp(0.8rem, 1.2vw, 1rem);
    background: #00b31a;
    border-radius: 50%;
    border: 3px solid rgba(255, 255, 255, 0.9);
    box-shadow: 0 0 0 3px rgba(0, 179, 26, 0.3);
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);

    @media (max-width: 480px) {
      display: none;
    }
  }

  .year-badge {
    position: absolute;
    right: clamp(0.8rem, 2vw, 1.5rem);
    top: -0.7rem;
    background: linear-gradient(45deg, #009b15, #00b31a);
    color: white;
    padding: 0.3rem 0.8rem;
    border-radius: 99px;
    font-weight: 600;
    font-size: 0.8rem;
    letter-spacing: 0.5px;
    box-shadow: 0 4px 15px rgba(0, 179, 26, 0.25);

    @media (max-width: 480px) {
      padding: 0.2rem 0.6rem;
      font-size: 0.65rem;
      right: 0.8rem;
      top: -0.6rem;
    }
  }
}


@keyframes fadeInDown {
to {
  opacity: 1;
  transform: translateY(0);
}
}

@keyframes expandWidth {
to {
  transform: scaleX(1);
}
}

@keyframes slideIn {
to {
  opacity: 1;
  transform: translateX(0);
}
}
